import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaLinkedin, FaGlobe, FaMedium, FaGithub } from 'react-icons/fa';
import heroimage from '../../images/HeroImage.jpg';
import { colors } from '@mui/material';

const AboutContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  max-width: 1100px;
  margin: auto;
  color: ${({ theme }) => theme.text_primary};

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    padding: 5px; 
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30%; 

  @media (max-width: 960px) {
    width: 100%; /* Full width on mobile */
    padding: 15px; /* Add padding for mobile */
    text-align: center; 
  }
`;


const ProfileImage = styled.img`
  width: 350px;
  height: 350px;
  border-radius: 5%;
  border: 3px solid ${({ theme }) => theme.primary};

  @media (max-width: 768px) {
    width: 100%; /* Full width on mobile */
    height: auto; /* Maintain aspect ratio */
    margin: 0 10px; /* Slight margin from the edges */
  }
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const SocialButton = styled.a`
  background: ${({ theme }) => theme.card};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;
  color: ${({ theme }) => theme.text_primary};

  &:hover {
    background: ${({ theme }) => theme.hover};
  }
`;

const AboutWrapper = styled.div`
  max-width: 65%;
  margin-left: 20px;

  @media (max-width: 960px) {
    max-width: 100%;
    margin-left: 0;
    text-align: center; 
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  color: ${({ theme }) => theme.text_primary};

  @media (max-width: 960px) {
    font-size: 28px; /* Reduce title size on mobile */
  }
`;

const Desc = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.text_secondary};
  line-height: 1.6;
  text-align: justify; 
  margin-bottom: 100px;

  @media (max-width: 960px) {
    font-size: 16px; /* Reduce text size on mobile */
    line-height: 1.4; /* Slightly tighter line spacing */
    margin-bottom: 50px; /* Adjust spacing */
  }
`;

const FirstLetter = styled.span`
  font-size: 48px; /* Adjust size as needed */
  font-weight: bold; /* Make it bold */
  line-height: 1; /* Ensures the height is consistent */
  margin-right: 5px; /* Space between the first letter and the text */
  color: #00ab6c;

  @media (max-width: 960px) {
    font-size: 40px; /* Adjust for mobile */
  }
`;

const About = () => {
  return (
    <AboutContainer id="about">
      <ImageContainer>
        <ProfileImage src={heroimage} alt="Your Name" />
        <SocialButtons>
          <SocialButton href="https://www.facebook.com/dipanjan.pathak.12/" target="_blank" rel="noopener noreferrer">
            <FaFacebook color="#0866ff" />
          </SocialButton>
          <SocialButton href="https://www.linkedin.com/in/dipanjanpathak/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin color="#0077b5" />
          </SocialButton>
          <SocialButton href="https://github.com/dipanjanpathak/" target="_blank" rel="noopener noreferrer">
            <FaGithub color="#db00ff" />
          </SocialButton>
          <SocialButton href="https://www.dipanjan.tech/" target="_blank" rel="noopener noreferrer">
            <FaGlobe color="#0e76a8" />
          </SocialButton>
          <SocialButton href="https://medium.com/@dipanjanpathak" target="_blank" rel="noopener noreferrer">
            <FaMedium color="#00ab6c" />
          </SocialButton>
        </SocialButtons>
      </ImageContainer>
      <AboutWrapper>
        <Title>About Me</Title>
        <Desc>
          
          <FirstLetter>A</FirstLetter> passionate data science enthusiast and a full-stack web developer. My journey is fueled by curiosity and a relentless pursuit of knowledge in transforming data into actionable insights.
          <br /><br />
          With expertise in machine learning and data analysis, I leverage the MERN stack to create meaningful digital experiences that bridge technology and analytics. I have worked on various projects that involved predictive modeling and data visualization, enabling data-driven decisions in real-world applications.
          <br /><br />
          I thrive on challenges and collaborative teamwork, always looking to enhance my skills and explore innovative solutions. Let's connect and embark on an extraordinary journey of discovery and impact together!
          <br /><br />
          As an <strong style={{color: '#00FF00'}}>INFJ</strong>, the rarest personality type in the world, I approach problem-solving with creativity, empathy, and a strong sense of purpose. This enables me to analyze complex challenges thoughtfully while maintaining a focus on broader objectives. My personality helps me balance innovative technical solutions with a people-centered approach, making sure the results are both impactful and aligned with the needs of the larger system or organization.
        </Desc>
      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
