import React from 'react';
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, LinkedIn, ButtonContainer, MobileIcon, MobileMenu, MobileLink } from './NavbarStyledComponent';
import { FaBars } from 'react-icons/fa';
import { Bio } from '../../data/constants';
import { useTheme } from 'styled-components';
import logo from '../../images/HeroImage.jpg';
import github from '../../images/github.png'
import LinkedInIcon from '../../images/linkedin.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme();

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <a style={{ display: "flex", alignItems: "center", cursor: 'pointer' }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                borderRadius: '50%',
                border: `3px solid ${theme.primary}`,
                width: '50px',
                height: '50px',
                marginRight: '10px',
              }}
            />
            <Span style={{ color: "white" }}>Dipanjan's</Span>
          </a>
        </NavLogo>

        <MobileIcon>
          <FaBars onClick={() => setIsOpen(!isOpen)} />
        </MobileIcon>

        <NavItems>
          <NavLink href="#about">About</NavLink>
          <NavLink href='#skills'>Overview</NavLink>
          <NavLink href='#experience'>Experience</NavLink>
          <NavLink href='#projects'>Projects</NavLink>
          <NavLink href='#education'>Education</NavLink>
          <NavLink href='#courses'>Courses</NavLink>
        </NavItems>

        <ButtonContainer>
          <GitHubButton href={Bio.github} target="_blank">
            <img src={github} alt="GitHub" style={{ width: '20px', marginRight: '8px' }} /> 
            Github
          </GitHubButton>
          
          <LinkedIn href={Bio.linkedin} target="_blank">
          <img src={LinkedInIcon} alt="GitHub" style={{ width: '23px', marginRight: '8px' }} /> 
           Linked In
          </LinkedIn>
        </ButtonContainer>

        {isOpen && (
          <MobileMenu isOpen={isOpen}>
            <MobileLink href="#about" onClick={() => setIsOpen(!isOpen)}>About</MobileLink>
            <MobileLink href='#skills' onClick={() => setIsOpen(!isOpen)}>Overview</MobileLink>
            <MobileLink href='#experience' onClick={() => setIsOpen(!isOpen)}>Experience</MobileLink>
            <MobileLink href='#projects' onClick={() => setIsOpen(!isOpen)}>Projects</MobileLink>
            <MobileLink href='#education' onClick={() => setIsOpen(!isOpen)}>Education</MobileLink>
            <GitHubButton style={{ padding: '10px 16px', background: `${theme.primary}`, color: 'white', width: 'max-content' }} href={Bio.github} target="_blank"><img src={github} alt="GitHub" style={{ width: '20px', marginRight: '8px' }} />
            Github</GitHubButton>
            <LinkedIn href={Bio.linkedin} style={{ padding: '10px 16px', background: `${theme.primary}`, color: 'white', width: 'max-content' }} target="_blank">
              <img src={LinkedInIcon} alt="GitHub" style={{ width: '23px', marginRight: '8px' }} />
              Linkend In</LinkedIn> 
          </MobileMenu>
        )}
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
